import React, { useState, useEffect } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import './SimplePanel.css'

// @ts-ignore
import { getData } from '../api/backend.js'
// @ts-ignore
import Main from "../views/Main/Main"
// @ts-ignore
import BranchOffice from '../views/BranchOffice/BranchOffice'

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: number;
  height: number;
}

export const SimplePanel: React.FC<Props> = ({ options, data }) => {
  const [selectedDashboard, setSelectedDashboard] = useState('main');
  const [dataBackend, setDataBackend] = useState<any>({});

  function changeDashboard(dashboard: string) {
    setSelectedDashboard(dashboard);
  }

  async function getDataBackend() {
    const backendData = await getData();
    setDataBackend(backendData);

  }

  useEffect(() => {
    getDataBackend();
  }, [data])

  return (
    <div
      className="panel-wrapper"
      style={{ width: '1800px', height: '1020px', backgroundColor: '#d8e1f5', padding: '15px' }}
    >
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"></link>
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" rel="stylesheet"></link>
      {selectedDashboard === "main" && <Main changeDashboard={changeDashboard} nagiosHosts={dataBackend} />}
      {selectedDashboard === "branch" && <BranchOffice changeDashboard={changeDashboard} />}
    </div>
  );
};
