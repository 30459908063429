import Logo from '../img/logo.png';
import Fortinet from '../img/Fortinet.png';
import Switch from '../img/hub.png';
import Shield from '../img/shield.png';
import BackArrow from '../img/back-arrow.png'

const icons = {
  logo: Logo,
  Fortinet: Fortinet,
  Switch: Switch,
  Shield: Shield,
  BackArrow: BackArrow,
};



export { icons};
