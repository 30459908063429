import React, { useState, useEffect } from 'react';
import WhiteContainer from '../../components/WhiteContainer/WhiteContainer';
import Logo from '../../img/logo.png';
import { icons } from '../../utils/dictionaryIcons';

import "./BranchOffice.css"
import AlertsTable from '../../components/AlertsTable/AlertsTable';
import RowInfraBranch from '../../components/RowInfraBranch/RowInfraBranch';

function BranchOffice({ changeDashboard }) {

    const alert_headers = [
        { "width": 35, "title": "CATEGORÍA" },
        { "width": 20, "title": "SATURACIÓN" },
        { "width": 20, "title": "LATENCIA" },
        { "width": 25, "title": "ANCHO DE BANDA" },
    ]

    const internet_data = [
        {status: "OK", name: "Switch", saturation: {value: "20%", status: "OK"}, latency: {value: "35 ms", status: "OK"}, bandwidth: {value: "100 Mbps", status: "OK"}},
        {status: "OK", name: "Fortinet", saturation: {value: "20%", status: "OK"}, latency: {value: "35 ms", status: "OK"}, bandwidth: {value: "100 Mbps", status: "OK"}},
        {status: "CRITICAL", name: "GTD (Vpn1)", saturation: {value: "20%", status: "WARNING"}, latency: {value: "35 ms", status: "WARNING"}, bandwidth: {value: "100 Mbps", status: "WARNING"}},
        {status: "OK", name: "GTD (Vpn2)", saturation: {value: "20%", status: "OK"}, latency: {value: "35 ms", status: "OK"}, bandwidth: {value: "100 Mbps", status: "OK"}},
        {status: "CRITICAL", name: "Movistar (Vpn1)", saturation: {value: "20%", status: "WARNING"}, latency: {value: "35 ms", status: "WARNING"}, bandwidth: {value: "100 Mbps", status: "WARNING"}},
        {status: "OK", name: "Movistar (Vpn2)", saturation: {value: "20%", status: "OK"}, latency: {value: "35 ms", status: "OK"}, bandwidth: {value: "100 Mbps", status: "OK"}},
    ]
    const internet_rows = internet_data.map(data => <RowInfraBranch branchInfo={data}/>)

    const teatinos_data = [
        {status: "CRITICAL", name: "Fortinet", saturation: {value: "20%", status: "CRITICAL"}, latency: {value: "35 ms", status: "CRITICAL"}, bandwidth: {value: "100 Mbps", status: "CRITICAL"}},
        {status: "CRITICAL", name: "GTD", saturation: {value: "20%", status: "CRITICAL"}, latency: {value: "35 ms", status: "CRITICAL"}, bandwidth: {value: "100 Mbps", status: "CRITICAL"}},
        {status: "CRITICAL", name: "Movistar", saturation: {value: "20%", status: "CRITICAL"}, latency: {value: "35 ms", status: "CRITICAL"}, bandwidth: {value: "100 Mbps", status: "CRITICAL"}},
        
    ]
    const teatinos_row = teatinos_data.map(data => <RowInfraBranch branchInfo={data}/>)

    const quilicura_data = [
        {status: "OK", name: "Fortinet", saturation: {value: "20%", status: "OK"}, latency: {value: "35 ms", status: "OK"}, bandwidth: {value: "100 Mbps", status: "OK"}},
        {status: "OK", name: "GTD", saturation: {value: "20%", status: "OK"}, latency: {value: "35 ms", status: "OK"}, bandwidth: {value: "100 Mbps", status: "OK"}},
        {status: "OK", name: "Movistar", saturation: {value: "20%", status: "OK"}, latency: {value: "35 ms", status: "OK"}, bandwidth: {value: "100 Mbps", status: "OK"}},
        
    ]
    const quilicura_row = quilicura_data.map(data => <RowInfraBranch branchInfo={data}/>)

    const InfraContent = [
        <WhiteContainer 
            title="Internet" 
            style={{height: "auto"}}
            content={<AlertsTable header_elements={alert_headers}>{internet_rows}</AlertsTable>}
        />,
        <WhiteContainer 
            title="DC Teatinos" 
            style={{height: "auto"}}
            content={<AlertsTable header_elements={alert_headers}>{teatinos_row}</AlertsTable>}
        />,
        <WhiteContainer 
            title="DC Quilicura" 
            style={{height: "auto"}}
            content={<AlertsTable header_elements={alert_headers}>{quilicura_row}</AlertsTable>}
        />
    ]

    

    const DcTeantinosContent = [
        <WhiteContainer title="Movistar" icon={icons.Shield} status={"OK"} style={{width: "160px", height: "50px", position: "absolute", top: "20px"}}/>,
        <WhiteContainer title="GTD" icon={icons.Shield} status={"OK"} style={{width: "160px", height: "50px", position: "absolute", top: "90px"}}/>,
        <WhiteContainer title="Fortinet" icon={icons.Fortinet} status={"OK"} style={{width: "160px", height: "50px", position: "absolute", top: "20px", right: "5px"}}/>,
        <div className='vertical-line' style={{width: "70px", position: "absolute", top: "65px", left: "164px"}} />,
        <div className='vertical-line' style={{width: "150px", position: "absolute", top: "131px", left: "164px"}} />,
        <div className='horizontal-line' style={{height: "46px", position: "absolute", top: "89px", left: "314px"}} />,
        <div className='vertical-line' style={{width: "80px", position: "absolute", top: "65px", right: "392px"}} />,
        <div className='vertical-line' style={{width: "50px", position: "absolute", top: "131px", right: "392px"}} />,
    ]

    const DcQuilicuraContent = [
        <WhiteContainer title="Fortinet" icon={icons.Fortinet} status={"OK"} style={{width: "160px", height: "50px", position: "absolute", top: "20px"}}/>,
        <WhiteContainer title="GTD" icon={icons.Shield} status={"OK"} style={{width: "160px", height: "50px", position: "absolute", top: "20px", right: "5px"}}/>,
        <WhiteContainer title="Movistar" icon={icons.Shield} status={"OK"} style={{width: "160px", height: "50px", position: "absolute", top: "90px", right: "5px"}}/>,
        <div className='vertical-line' style={{width: "70px", position: "absolute", top: "65px", left: "164px"}} />,
        <div className='vertical-line' style={{width: "150px", position: "absolute", top: "131px", right: "164px"}} />,
        <div className='horizontal-line' style={{height: "46px", position: "absolute", top: "89px", left: "80px"}} />,
        <div className='vertical-line' style={{width: "80px", position: "absolute", top: "65px", left: "392px"}} />,
        <div className='vertical-line' style={{width: "50px", position: "absolute", top: "131px", left: "392px"}} />,

    ]

    const branchContent = [
        <WhiteContainer title="GTD" icon={icons.Shield} status={"OK"} style={{width: "160px", height: "50px", position: "absolute", top: "20px"}}/>,
        <WhiteContainer title="Movistar" icon={icons.Shield} status={"OK"} style={{width: "160px", height: "50px", position: "absolute", top: "20px", right: "5px"}}/>,
        <WhiteContainer title="Fortinet" icon={icons.Fortinet} status={"OK"} style={{width: "160px", height: "50px", position: "absolute", top: "90px", left: "120px"}}/>,
        <WhiteContainer title="Switch" icon={icons.Switch} status={"OK"} style={{width: "160px", height: "50px", position: "absolute", top: "160px", left: "120px"}}/>,
    ]

    const cloudContent = [
        <div className='cloud-text' style={{position: "absolute", top: "20px", left: "60px"}}>Cloud</div>,
        <i class="fa-solid fa-cloud" style={{fontSize: "80px", position: "absolute", top: "50px", left: "40px"}}/>
    ]

    return (
        <section className="branch-view">
            <div className='column1' >
                <img src={Logo}></img>
                <WhiteContainer title= "Clinica Bicentenario" content={InfraContent} statusRight={"OK"} style={{ height: "calc(100% - 168px)" }} noHover={true}/>
            </div>
            <div className='column2'>
                <WhiteContainer 
                    title="DC Teatinos" 
                    style={{width: "400px", height: "180px", position: "absolute", top: "150px", left: "150px"}} 
                    noHover={true}
                    content={DcTeantinosContent}
                />
                <WhiteContainer 
                    title="DC Quilicura" 
                    style={{width: "400px", height: "180px", position: "absolute", top: "150px", right: "150px"}} 
                    noHover={true}
                    content={DcQuilicuraContent}
                />
                <WhiteContainer 
                    title="Clinica Bicentenario" 
                    style={{width: "400px", height: "250px", position: "absolute", top: "480px", left: "400px"}} 
                    noHover={true} 
                    content={branchContent}
                />

                <WhiteContainer  
                    style={{width: "180px", height: "150px", position: "absolute", top: "770px", left: "510px"}} 
                    noHover={true}
                    content={cloudContent}
                />

                <div className='horizontal-line' style={{height: "170px", position: "absolute", top: "236px", left: "77px"}} />
                <div className='vertical-line' style={{width: "780px", position: "absolute", top: "406px", left: "77px"}} />
                <div className='horizontal-line' style={{height: "155px", position: "absolute", top: "406px", left: "857px"}} />
                <div className='vertical-line' style={{width: "68px", position: "absolute", top: "561px", left: "793px"}} />

                <div className='horizontal-line' style={{height: "220px", position: "absolute", top: "236px", right: "77px"}} />
                <div className='vertical-line' style={{width: "780px", position: "absolute", top: "456px", right: "77px"}} />
                <div className='horizontal-line' style={{height: "105px", position: "absolute", top: "456px", right: "857px"}} />
                <div className='vertical-line' style={{width: "82px", position: "absolute", top: "561px", right: "779px"}} />

                <div className='horizontal-line' style={{height: "278px", position: "absolute", top: "302px", right: "107px"}} />
                <div className='vertical-line' style={{width: "285px", position: "absolute", top: "576px", right: "107px"}} />

                <div className='horizontal-line' style={{height: "278px", position: "absolute", top: "302px", left: "107px"}} />
                <div className='vertical-line' style={{width: "300px", position: "absolute", top: "576px", left: "107px"}} />

                <div className='horizontal-line' style={{height: "51px", position: "absolute", top: "561px", left: "610px"}} />
                <div className='vertical-line' style={{width: "25px", position: "absolute", top: "561px", left: "610px"}} />

                <div className='horizontal-line' style={{height: "51px", position: "absolute", top: "561px", right: "595px"}} />
                <div className='vertical-line' style={{width: "25px", position: "absolute", top: "561px", right: "595px"}} />

                <div className='horizontal-line' style={{height: "22px", position: "absolute", top: "660px", right: "584px"}} />

                <div className='horizontal-line' style={{height: "302px", position: "absolute", top: "590px", left: "435px"}} />
                <div className='vertical-line' style={{width: "75px", position: "absolute", top: "888px", left: "435px"}} />
                <div className='horizontal-line' style={{height: "269px", position: "absolute", top: "590px", left: "460px"}} />
                <div className='vertical-line' style={{width: "50px", position: "absolute", top: "855px", left: "460px"}} />


                <div className='horizontal-line' style={{height: "302px", position: "absolute", top: "590px", right: "420px"}} />
                <div className='vertical-line' style={{width: "75px", position: "absolute", top: "888px", right: "422px"}} />
                <div className='horizontal-line' style={{height: "269px", position: "absolute", top: "590px", right: "445px"}} />
                <div className='vertical-line' style={{width: "50px", position: "absolute", top: "855px", right: "447px"}} />

            </div>
            <img className='back-arrow' src={icons.BackArrow} onClick={()=>changeDashboard("main")}></img>
        </section>
    )
}
    

export default BranchOffice;