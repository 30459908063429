// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.branch-view{
    display: flex;
    height: 100%;
    width: 100%;
    background: #eff5ff;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    position: relative;
}
.branch-view .column1 img{
    width: 300px;
    margin: 30px 0px;
}

.branch-view .column1{
    height: 100%;
    width: 33%;
    padding: 10px;
}

.branch-view .column2{
    background-color: #e8ecf4;
    position: relative;
    height: 100%;
    width: 67%;
    padding: 10px;
    border-radius: 0px 20px 20px 0px;
}

.branch-view .column2 .cloud-text{
    font-family: Sansation;
    font-size: 20px;
    font-weight: 700;
    line-height: 22.38px;
    letter-spacing: 0.04em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.branch-view .back-arrow{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    pointer-events: auto;
}`, "",{"version":3,"sources":["webpack://./views/BranchOffice/BranchOffice.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,sFAAsF;IACtF,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,kBAAkB;IAClB,kCAAkC;IAClC,8BAA8B;;AAElC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".branch-view{\n    display: flex;\n    height: 100%;\n    width: 100%;\n    background: #eff5ff;\n    border-radius: 20px;\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;\n    position: relative;\n}\n.branch-view .column1 img{\n    width: 300px;\n    margin: 30px 0px;\n}\n\n.branch-view .column1{\n    height: 100%;\n    width: 33%;\n    padding: 10px;\n}\n\n.branch-view .column2{\n    background-color: #e8ecf4;\n    position: relative;\n    height: 100%;\n    width: 67%;\n    padding: 10px;\n    border-radius: 0px 20px 20px 0px;\n}\n\n.branch-view .column2 .cloud-text{\n    font-family: Sansation;\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 22.38px;\n    letter-spacing: 0.04em;\n    text-align: center;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n\n}\n\n.branch-view .back-arrow{\n    width: 30px;\n    height: 30px;\n    position: absolute;\n    right: 10px;\n    top: 10px;\n    cursor: pointer;\n    pointer-events: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
