import React from 'react';
import './RowInfraBranch.css';
import { getStatusColorByType } from '../../utils/color';
function RowInfraBranch({ branchInfo }) {

    return (
        <div className='row-infra'>
            <div style={{width: "35%", display: "flex", alignItems: "center"}}>
                <div className='device-status' style={{backgroundColor: getStatusColorByType(branchInfo.status), marginRight: "5px"}}/>
                {branchInfo.name}
            </div>
            <div className='column' style={{width: "20%", color: getStatusColorByType(branchInfo.saturation.status)}}>
                {branchInfo.saturation.value}
            </div>
            <div className='column' style={{width: "20%", color: getStatusColorByType(branchInfo.latency.status)}}>
                {branchInfo.latency.value}
            </div>
            <div className='column' style={{width: "25%", color: getStatusColorByType(branchInfo.bandwidth.status)}}>
                {branchInfo.bandwidth.value}
            </div>
        </div>
    );
}

export default RowInfraBranch;