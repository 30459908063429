// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-alert{
    padding: 10px 10px;
    border: solid 1px #C9D6F2;
    border-radius: 10px;
    margin: 5px 0px;
}

.row-alert:hover{
    background-color: #f4f9fc;
    border: 2px solid #1bdbce;
}

.row-alert .alert-data{
    display: flex;
    margin-bottom: 5px;
    font-size: 12px;
}

.row-alert .alert-data .alert-status{
    width: 15px;
    height: 15px;
    border-radius: 3px;
}

.row-alert .alert-detail{
    border-top: 1px solid #C9D6F2;
}
`, "",{"version":3,"sources":["webpack://./components/RowAlert/RowAlert.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".row-alert{\n    padding: 10px 10px;\n    border: solid 1px #C9D6F2;\n    border-radius: 10px;\n    margin: 5px 0px;\n}\n\n.row-alert:hover{\n    background-color: #f4f9fc;\n    border: 2px solid #1bdbce;\n}\n\n.row-alert .alert-data{\n    display: flex;\n    margin-bottom: 5px;\n    font-size: 12px;\n}\n\n.row-alert .alert-data .alert-status{\n    width: 15px;\n    height: 15px;\n    border-radius: 3px;\n}\n\n.row-alert .alert-detail{\n    border-top: 1px solid #C9D6F2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
