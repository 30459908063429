import React, { useState, useEffect } from 'react';
import WhiteContainer from '../../components/WhiteContainer/WhiteContainer';
import DeviceStatus from '../../components/DeviceStatus/DeviceStatus';
import FiberLink from '../../components/FiberLink/FiberLink';
import SimpleDropDown from '../../components/SimpleDropDown/SimpleDropDown';
import ProvidersInfo from '../../components/ProvidersInfo/ProvidersInfo';
import AlertsTable from '../../components/AlertsTable/AlertsTable';
import RowAlert from '../../components/RowAlert/RowAlert';
import RowBranch from '../../components/RowBranch/RowBranch';
import CriticalServices from '../../components/CriticalServices/CriticalServices';
import { global_states, number_status } from '../../utils/dictionaryStates';
import Logo from '../../img/logo.png';
import './Main.css'

function Main({ changeDashboard, nagiosHosts}) {
    const [dataCenterItems, setDataCenterItems] = useState([]);

    //Fiber link data

    const fiber_data = [
        { from: 'NEXUS1', to: 'NEXUS2', status: 'UNKNOWN' },
        { from: 'NEXUS3', to: 'NEXUS4', status: 'UNKNOWN' },
        { from: 'BBDD1', to: 'BBDD2', status: 'UNKNOWN' },
        { from: 'BBDD1', to: 'BBDD2', status: 'UNKNOWN' },
    ]
    const fiber_body = fiber_data.map((link) => {
        const body = <FiberLink status={link.status} from={link.from} to={link.to} />
        return <WhiteContainer content={body} />
    });

    //Fortinet data
    const fortinet_elems = [];
    fortinet_elems.push(<SimpleDropDown title='Down' status='UNKNOWN' statusInfo='0' />);
    fortinet_elems.push(<SimpleDropDown title='Up' status='UNKNOWN' statusInfo='0' />);
    const fortinet_body = <div className='flex-wrap'>{fortinet_elems}</div>

    //Proveedores data
    const proveedores_data = { down: 0, total: 0, sla_percentage: '%' };
    const proveedores_body = <ProvidersInfo down={proveedores_data.down} total={proveedores_data.total} slaPercentage={proveedores_data.sla_percentage} />

    //Alerts data 
    const alert_headers = [
        { width: 5,  title: 'EST' },
        { width: 25, title: 'SUCURSAL' },
        { width: 20, title: 'EQUIPO' },
        { width: 25, title: 'FECHA / HORA' },
        { width: 15, title: 'TIEMPO' },
        { width: 10, title: 'REVISAR' }
    ];

    const alerts_data = [
        { status: 'UNKNOWN', branch: 'Clínica Bicentenario', team: 'RMKWJ4782', date: '09/12/2024  14:44', time: '3:07 MIN', detail: 'Router Código RMKWJ4782 desconectado...' },
        { status: 'UNKNOWN', branch: 'Clínica Bicentenario', team: 'RMKWJ4782', date: '09/12/2024  14:44', time: '3:07 MIN', detail: 'Router Código RMKWJ4782 desconectado...' },
        { status: 'UNKNOWN', branch: 'Clínica Bicentenario', team: 'RMKWJ4782', date: '09/12/2024  14:44', time: '3:07 MIN', detail: 'Router Código RMKWJ4782 desconectado...' },
        { status: 'UNKNOWN', branch: 'Clínica Bicentenario', team: 'RMKWJ4782', date: '09/12/2024  14:44', time: '3:07 MIN', detail: 'Router Código RMKWJ4782 desconectado...' },
        { status: 'UNKNOWN', branch: 'Clínica Bicentenario', team: 'RMKWJ4782', date: '09/12/2024  14:44', time: '3:07 MIN', detail: 'Router Código RMKWJ4782 desconectado...' },
    ];
    const alert_rows = alerts_data.map((alert) => <RowAlert alert={alert} />)

    //Branch data
    const branch_headers = [
        { width: 50, title: 'NOMBRE SUCURSAL'},
        { width: 10, title: 'ALERT'},
        { width: 10, title: 'GTD'},
        { width: 10, title: 'MOV'},
        { width: 10, title: 'FORT'},
        { width: 10, title: 'TPACK'},
    ]

    const branch_data = [
        { status: 'UNKNOWN', name: 'Clínica Bicentenario', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Clínica Bupa', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Clínica Indisa', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Clínica Tabancura', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Clínica Vespucio', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Agustinas', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Antofagasta', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Arica', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Calama', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Castro', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Chillán', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Concepción', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Constitución', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Copiapó', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Coyhaique', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Curicó', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Huérfanos', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Iquique', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'La Serena', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Los Andes', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Los Ángeles', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' },
        { status: 'UNKNOWN', name: 'Maipú', amount: 0, GTDstatus: 'UNKNOWN', MOVstatus: 'UNKNOWN', FORTstatus: 'UNKNOWN', TPACKstatus: 'UNKNOWN' }
    ]

    const branch_rows = branch_data.map((branch) => <RowBranch branchInfo={branch} changeDashboard={changeDashboard}/>)
    
    //critical services data
    const critical_services_status = {router: 'UNKNOWN', saturation: 'UNKNOWN', interface:'UNKNOWN', conection: 'UNKNOWN'};
    const critical_service_body = <CriticalServices status={critical_services_status} />


    useEffect(()=>{
        const datacenterHosts = {
            Teatinos: {
                Switch: [
                    'IC4_S_SW_Sonda-1',
                    'SW-Consalud-Sonda-2',
                    'Sw3_Consalud_Sonda',
                    'SW4_SUC_DC_TEATINOS',
                    'TEA-N3K-P',
                    'TEA-N3K-Q'
                ],
                Router: [],
                Firewall: [],
                Sdwan: [],
            },
            Quilicura: {
                Switch: [
                    'QUI-N3K-P',
                    'QUI-N3K-R',
                    'CONSALUD-SONDA-QUILICURA',
                    'SW2-Consalud-Quilicura',
                    'Sw3-Consalud-Quilicura',                    
                ],
                Router: [],
                Firewall: [],
                Sdwan: [],
            }
        }

        const datacenterItemsTemp = Object.entries(datacenterHosts).map(([datacenterName, datacenterData]) => {
            let dataCenterStatus = 0;
            let totalProblems = 0;
            const datacenterElems = Object.entries(datacenterData).map(([deviceType, hosts]) => {
                let devicesTotal = 0;
                let devicesProblems = 0;
                let devicesStatus = 0;
                hosts.forEach(host => {
                    const hostItem = nagiosHosts[host];
                    if (hostItem) {
                        devicesTotal += 1;
                        if (hostItem.state !== 0) {
                            devicesProblems += 1;
                            totalProblems += 1;
                            if (hostItem.state > devicesStatus) {
                                devicesStatus = hostItem.state;
                            }
                            if (hostItem.state > dataCenterStatus) {
                                dataCenterStatus = hostItem.state;
                            }
                        }
                    }
                });
                return <DeviceStatus deviceType={deviceType} status={global_states[devicesStatus]} upHost={`${devicesProblems}/${devicesTotal}`} cpu='%' ram='%' />
            });
            const datacenterBody = (
                <div className='flex-wrap'>
                    {datacenterElems}
                </div>
            );
            return <WhiteContainer title={datacenterName} subtitle='ESTADO DATACENTER' status={global_states[dataCenterStatus]} statusContent='0' content={datacenterBody} style={{ height: '240px' }} noHover={true} />
        });
        setDataCenterItems(datacenterItemsTemp);


    }, [nagiosHosts])

    return (
        <section className='panel-view'>
            <div className='column1'>
                <img src={Logo}></img>
                <div className='title'>Fibra Oscura</div>
                {fiber_body}
            </div>
            <div className='column2'>
                <div className='datacenter'>
                    <div className='title'>Datacenter</div>
                    {dataCenterItems}
                </div>
                <div className='fortinet'>
                    <div className='title'>Fortinet</div>
                    <WhiteContainer title='Forntinet 600F' subtitle='CONTEO DE VPN' content={fortinet_body} style={{ height: '100px' }} />
                    <WhiteContainer title='Forntinet 200F' subtitle='CONTEO DE VPN' content={fortinet_body} style={{ height: '100px' }} />
                </div>
            </div>
            <div className='column3'>
                <div className='row1'>
                    <div className='column1'>
                        <div className='title'>Proveedores</div>
                        <WhiteContainer title='Todos' subtitle='SUCURSALES ALERTADAS' status='UNKNOWN' statusContent='0/0' content={proveedores_body} style={{ height: '110px' }} />
                        <WhiteContainer title='GTD' subtitle='SUCURSALES ALERTADAS' status='UNKNOWN' statusContent='0/0' content={proveedores_body} style={{ height: '110px' }} />
                        <WhiteContainer title='Movistar' subtitle='SUCURSALES ALERTADAS' status='UNKNOWN' statusContent='0/0' content={proveedores_body} style={{ height: '110px' }} />
                    </div>
                    <div className='column2'>
                        <div className='title'>Servicios críticos</div>
                        <WhiteContainer title='GTD' subtitle='INTERNET' horizontal={true} content={critical_service_body}/>
                        <WhiteContainer title='Movistar' subtitle='INTERNET'  horizontal={true} content={critical_service_body}/>
                        <WhiteContainer title='Wifi' subtitle='INTERNET' horizontal={true} content={critical_service_body}/>
                        <WhiteContainer title='I-Med1' subtitle='INTERNET' horizontal={true} content={critical_service_body}/>
                        <WhiteContainer title='I-Med2' subtitle='INTERNET' horizontal={true} content={critical_service_body}/>
                    </div>
                </div>
                <div className='row2'>
                    <div className='title'>Últimas alertas</div>
                    <WhiteContainer 
                        content={
                            (
                                <AlertsTable header_elements={alert_headers} height={310}>
                                    {alert_rows}
                                </AlertsTable>
                            )
                        }
                        noHover={true}
                        style={{ height: '320px' }} 
                    />
                </div>

            </div>
            <div className='column4'>
                <div className='title'>Sucursales - Detalle de estado</div>
                <div className='flex-wrap'>
                    <SimpleDropDown title='Todas' subTitle='Región'/>
                    <SimpleDropDown title='Todos' subTitle='Estado'/>
                </div>
                <WhiteContainer 
                    style={{ height: '857px' }}
                    noHover={true}
                    content={(
                        <AlertsTable header_elements={branch_headers} height={847}>
                            {branch_rows}
                        </AlertsTable>
                    )}
                >
                </WhiteContainer>
            </div>
            {/* <Toolbar title={`Panel Ejecutivo`} /> */}
        </section>
    )
}

export default Main;