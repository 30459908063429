import React from 'react';
import './WhiteContainer.css';
import { getStatusColorByType } from '../../utils/color';
function WhiteContainer({ style, title, subtitle, status, icon, statusRight, statusContent, content, horizontal, noHover }) {
  if(horizontal){
    return(
      <div className={noHover? "white-container no-hover" : "white-container"} style={style}>
        <div className='header'>
          <div className='header-titles'>
            <div className='header-title'>{title}</div>
            <div className='sub-title'>{subtitle}</div>
            <i class="fa-solid fa-eye" style={{width: "10%"}}></i>
          </div>
          <div className='body-horizontal'>
            {content}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={noHover? "white-container no-hover" : "white-container"} style={style}>
      <div className='header'>
        <div className='header-titles'>
          <div style={{display: "flex"}}>
            {icon && <img src={icon}></img>}
            {statusRight && <div className='status-square' style={{backgroundColor: getStatusColorByType(statusRight)}}/>}
            <div className='header-title'>{title}</div>
          </div>
          <div className='sub-title'>{subtitle}</div>
        </div>
        {status && (
          <div
            className='status'
            style={statusContent ? { background: getStatusColorByType(status) }: { background: getStatusColorByType(status), width: "22px" }}
          >
            {statusContent}
          </div>
        )}
      </div>
      <div className='content'>{content}</div>
    </div>
  );
}

export default WhiteContainer;
