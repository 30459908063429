// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-div{
    color: #A0A1A5;
}

.alert-div .t-header{
    display: flex;
    padding: 0px 10px;
    font-size: 8px;
    font-weight: 700;
    height: 13px;
}

.alert-div .t-body{
    overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./components/AlertsTable/AlertsTable.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".alert-div{\n    color: #A0A1A5;\n}\n\n.alert-div .t-header{\n    display: flex;\n    padding: 0px 10px;\n    font-size: 8px;\n    font-weight: 700;\n    height: 13px;\n}\n\n.alert-div .t-body{\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
